<template>
  <div class="board-page">
    <section class="hero is-primary">
      <div class="hero-body has-text-centered">
        <div class="container">
          <h1 class="title is-size-2">Board of Directors</h1>
        </div>
      </div>
    </section>
    <section class="main container">
      <div class="columns is-multiline is-centered">
        <div class="column is-full-mobile is-half-tablet is-one-third-desktop" v-for="member in boardMembers" :key="member.name">
          <div class="card">
            <div class="card-image">
              <figure class="image is-1by1">
                <img :src="member.image" :alt="`${member.name} - Board Member`" />
              </figure>
            </div>
            <div class="card-content">
              <p class="title is-5">{{ member.name }}</p>
              <p class="subtitle is-6">{{ member.position }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Board",
  data() {
    return {
      boardMembers: [
        {
          name: "Maciej Smusz",
          position: "President",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fzywiec%20headshot%20transparent.png?alt=media&token=c8b17a20-1b40-43e1-92b9-7a0db2ed9c74",
        },
        {
          name: "Daniel Smela",
          position: "Vice President",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fdaniel.png?alt=media&token=db32d974-0f73-4217-95e7-027cf32ac911",
        },
        {
          name: "Yessica Króżel",
          position: "Secretary",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2Fyessica.png?alt=media&token=c01b5187-d7d0-4c1b-a8c3-c0ae642d4600",
        },
        {
          name: "Matthew Schmit",
          position: "Treasurer",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2FMatt.png?alt=media&token=96a93dbe-b22e-4536-969f-f8c513d06598",
        },
        {
          name: "Kuba Gubała",
          position: "Sergeant at Arms",
          image:
            "https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/board_headshots%2FJakub.png?alt=media&token=366cc078-e16c-47ba-9cdc-cdef4869306d",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.board-page {
  .hero-body {
    background: linear-gradient(to right, #93291e, #ed213a);
    color: white;
    padding: 2.5rem 1rem;

    h1 {
      font-size: 2.2rem;
      font-weight: 700;
    }
  }

  .main {
    margin-top: 30px;

    .columns {
      justify-content: center;
    }

    .card {
      width: 240px; /* Set fixed width to make cards skinnier */
      margin: 15px auto;
      border-radius: 8px;
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      }

      .card-image {
        .image {
          border-radius: 8px 8px 0 0;
          overflow: hidden;

          img {
            object-fit: cover;
            width: 100%;
            height: 240px; /* Fixed height for image to maintain uniformity */
          }
        }
      }

      .card-content {
        padding: 1rem;
        text-align: center;

        .title {
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 0.3rem; /* Ensure proper spacing below the name */
          line-height: 1.4; /* Prevent overlapping with the subtitle */
        }

        .subtitle {
          font-size: 1rem;
          color: #6b6b6b;
          margin-top: 0.2rem; /* Ensure proper spacing above the position */
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-body {
    padding: 1.5rem 1rem;

    h1 {
      font-size: 2rem;
    }
  }

  .card {
    width: 100%; /* Full width on smaller screens */
    max-width: 320px; /* Limit max width on mobile */
    margin: 10px auto;

    .card-image {
      img {
        height: 200px; /* Slightly reduce image height on mobile */
      }
    }

    .card-content {
      padding: 0.75rem;

      .title {
        font-size: 1.1rem;
      }

      .subtitle {
        font-size: 0.9rem;
      }
    }
  }
}
</style>
